@charset "UTF-8";
body, html {
  width: 100vw;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.c-overlay {
  background-color: rgba(50, 50, 50, 0.8);
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.6s;
  z-index: 20;
  pointer-events: none;
}
.c-overlay.--visible {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}
.c-contact {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  transition: 0.6s;
  z-index: 21;
  visibility: hidden;
}
.c-contact h1, .c-contact h2 {
  line-height: 118%;
  font-family: gilroy-semi-bold;
  color: #383838;
}
.c-contact .line-parent {
  overflow: hidden;
  display: block;
}
.c-contact .line-child {
  display: block;
  transform-origin: center bottom;
  transform-style: preserve-3d;
  transform: translate(0, -100%);
  opacity: 0;
  transition: 0;
}
.c-contact > div, .c-contact form {
  z-index: 2;
  position: relative;
  transition: 0.4s;
  transition-delay: 0;
}
.c-contact .c-error {
  position: absolute;
  bottom: 18vh;
  padding: 15px 25px;
  left: 8vw;
  font-family: gilroy, sans-serif;
  background-color: #DD1E2E;
  border-radius: 100px;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  z-index: 8;
  color: #fff;
}
.c-contact::after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scaleX(0);
  transform-origin: left;
  background: #fff;
  transition: 0.6s;
  transition-delay: 0.4s;
  will-change: transform;
}
.c-contact.--visible {
  visibility: visible;
}
.c-contact.--visible::after {
  transform: scaleX(1);
  transition-delay: 0.1s;
}
.c-contact.--visible > div, .c-contact.--visible form {
  opacity: 1;
  transition: 0.6s;
  transition-delay: 0.6s;
}
.c-contact.--visible .c-error.--visible {
  opacity: 1;
  visibility: visible;
}
.c-contact .c-progress {
  width: 100%;
  height: 1%;
  opacity: 0;
}
.c-contact .c-progress span {
  height: 100%;
  width: 100%;
  background: #14CF93;
  transform: scale(0, 1);
  transform-origin: left;
  display: block;
  transition: transform 0.6s;
  will-change: transform;
}
.c-contact form {
  width: 100%;
  height: 71%;
  opacity: 0;
  overflow: hidden;
}
.c-contact form .c-slider {
  display: flex;
  align-items: flex-start;
  transition: transform 0.65s;
  transition-delay: 0.6s;
  will-change: transform;
  height: 100%;
}
.c-contact form .c-slider > div {
  min-width: 100%;
}
.c-contact .c-buttons {
  width: 100%;
  height: 14%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8vw;
  opacity: 0;
}
.c-contact .c-buttons > div {
  transition: 0.4s;
  cursor: pointer;
}
.c-contact .c-buttons #js-count {
  cursor: default;
}
.c-contact .c-buttons .c-swap {
  position: relative;
  cursor: default;
}
.c-contact .c-buttons .c-swap > div {
  padding: 18px 80px;
}
.c-contact .c-buttons .c-swap button {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: none;
  background: #14CF93;
  color: #fff;
  z-index: 4;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
  border-radius: 100px;
  font-size: 16px;
}
.c-contact .c-buttons .c-close, .c-contact .c-buttons .c-back {
  padding: 18px;
  background-color: #14CF93;
  line-height: 0;
  border-radius: 100px;
}
.c-contact .c-buttons .c-back {
  background-color: #ddd;
}
.c-contact .c-buttons .c-skip {
  visibility: hidden;
  opacity: 0;
  border: 2px solid #eee;
  border-radius: 100px;
  cursor: pointer;
}
.c-contact .c-buttons .c-skip p {
  color: #282828;
}
@keyframes reply_show {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translate(30%, 0);
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }
}
@keyframes reply_hide {
  from {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }
  to {
    opacity: 0;
    visibility: hidden;
    transform: translate(0, 20%);
  }
}
.c-query {
  padding: 6vh 8vw;
  height: 100%;
}
.c-query.--hidden .c-reply > div {
  animation: reply_hide 0.6s forwards;
  animation-delay: calc(var(--order) * 100ms);
}
.c-query.--visible {
  overflow-y: scroll;
  overflow-x: hidden;
}
.c-query.--visible .c-reply > div {
  animation-play-state: running;
}
.c-query .c-title {
  font-size: 20px;
  width: 100%;
  padding: 0;
}
.c-query .c-reply {
  min-width: 100%;
  padding: 4% 0;
}
.c-query .c-reply > div {
  margin: 16px 0;
  width: 90%;
  position: relative;
  animation: reply_show 0.6s backwards;
  animation-delay: calc(var(--order) * 100ms);
  animation-play-state: paused;
}
.c-query .c-reply > div::after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #14CF93;
  height: 100%;
  opacity: 0;
  border-radius: 200px;
  transform: scaleX(0);
  transform-origin: center;
  transition: 0.4s;
  z-index: 0;
}
.c-query .c-reply > div:hover {
  color: #fff;
}
.c-query .c-reply > div:focus-within::after {
  visibility: hidden;
}
.c-query .c-reply input, .c-query .c-reply textarea {
  width: 100%;
  border: none;
  background: transparent;
  padding: 10px 0 10px 40px;
  margin: 10px 0;
  font-size: 18px;
  opacity: 1;
  transition: 0.4s;
  font-family: gilroy, sans-serif;
}
.c-query .c-reply input:focus, .c-query .c-reply textarea:focus {
  padding-left: 5px;
  outline: none;
}
.c-query .c-reply input::-moz-focus-inner, .c-query .c-reply textarea::-moz-focus-inner {
  border: 0;
}
.c-query .c-reply input[type=radio] {
  opacity: 0;
  display: none;
}
.c-query .c-reply label {
  position: relative;
  text-align: center;
  cursor: pointer;
  border-radius: 200px;
  color: #292929;
  box-shadow: inset 0 0 #555;
  transition: box-shadow 0.4s;
  opacity: 1;
  z-index: 2;
  display: block;
  width: 100%;
  padding: 20px 0;
  height: 100%;
  border: 1px solid #ddd;
}
.c-query .c-reply input[type=radio]:checked + label {
  box-shadow: inset 0 500px #14CF93;
  color: #fff;
}
.s-text {
  position: relative;
  display: block;
  border-bottom: 2px solid #eee !important;
}
.s-text.s-text-area {
  border: 2px solid #eee;
  border-radius: 16px;
  width: 100% !important;
}
.s-text.s-text-area textarea {
  max-width: 100%;
}
.s-text.s-text-area textarea:focus {
  padding-left: 20px;
}
.s-text.s-text-area::before {
  top: 30.5px;
}
.s-text::after {
  display: none !important;
}
.s-text:focus-within {
  outline: none;
  -moz-outline: none;
  border-bottom: 3px solid #14CF93 !important;
}
.s-text::-moz-focus-inner {
  border: 0;
}
.s-text::before {
  content: url("drop-down.0028b9b3.svg");
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translate(0, -50%) rotate(-90deg);
  transition: 0.4s;
}
.s-text:focus-within::before {
  opacity: 0;
  transform: translate(12px, -50%) rotate(-90deg);
}
.c-message {
  padding: 4vh 8vw;
}
.c-message img {
  float: right;
  transform: rotate(180deg) translate(-10px, 0);
  transition: 0.4s;
  opacity: 0;
  width: 35px;
  transition-delay: 0.4s;
  position: relative;
}
.c-message p {
  color: #414141;
  text-transform: none;
  opacity: 0.8;
}
.c-message span {
  display: inline-block;
  width: 100px;
  height: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  border-radius: 200px;
  opacity: 0;
  transform: translate(0, 20px);
  background-color: #F6F6F6;
  transition: 0.4s;
  transition-delay: 0.4s;
}
.c-message.--visible img {
  opacity: 1;
  transform: rotate(180deg) translate(0, 10px);
  transition-delay: 0.8s;
}
.c-message.--visible span {
  transform: translate(0, 0);
  opacity: 1;
}
.c-message.--welcome h1 {
  font-size: 45px;
  padding: 30px 0;
}
.c-message.--welcome p {
  font-size: 28px;
  width: 90%;
  padding: 10px 0;
}
.c-cate {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  padding-top: 15vh;
  z-index: 15;
  text-align: center;
  display: none;
  display: none;
}
.c-cate img {
  width: 85%;
}
.c-cate div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  position: relative;
  left: 50%;
  top: 6%;
  transform: translate(-50%, 0);
}
.c-cate div button {
  width: 46%;
  border: 1px solid #eee;
  border-radius: 60px;
  background: transparent;
  padding: 10px 0;
  margin: 10px 0;
  font-size: 18px;
  opacity: 1;
  transition: 0.4s;
  font-family: gilroy, sans-serif;
}
.c-cate div button:hover {
  background-color: #14CF93;
  color: #fff;
}
@media only screen and (min-width: 700px) {
  .c-contact {
    width: 45%;
  }
  .c-contact .c-error {
    left: 5vw;
  }
  .c-contact .c-buttons {
    padding: 0 5vw;
  }

  .c-message {
    padding: 4vh 5vw;
  }

  .c-query {
    padding: 8vh 5vw;
  }
  .c-query .c-title {
    font-size: 26px;
    width: 90%;
  }
  .c-query.--visible {
    overflow: hidden;
  }
  .c-query .c-reply {
    padding: 3% 0;
  }
  .c-query .c-reply > div {
    width: 60%;
    margin: 14px 0;
  }
  .c-query .c-reply > div:hover::after {
    opacity: 0.5;
    transform: scaleX(1);
  }

  .s-text.s-text-area {
    width: 90% !important;
  }

  .c-cate img {
    width: 34%;
  }
  .c-cate div {
    width: 35%;
  }
}
.c-slider > div.--visible .line-child {
  opacity: 1;
  transform: translate(0, 0);
  transition: 0.6s;
}
.c-slider > div.--hidden .line-child {
  opacity: 1;
  transform: translate(0, 100%);
  transition: 0.4s;
}
@font-face {
  font-family: mazius;
  src: url("Mazius-Extraitalic.121a71a7.otf");
}
@font-face {
  font-family: gilroy;
  src: url("Gilroy-Medium.e7e7c091.ttf");
}
@font-face {
  font-family: gilroy-semi-bold;
  src: url("Gilroy-SemiBold.f3ed44cd.ttf");
}
@font-face {
  font-family: circular;
  src: url(CircularStd-Book.4420c11b.woff2);
}
body {
  background-color: #151515;
}
* {
  box-sizing: border-box;
  margin: 0;
}
main {
  position: relative;
  z-index: 3;
}
h1, h2, h3, h4, h5 {
  line-height: 94%;
  font-family: gilroy, sans-serif;
  font-weight: normal;
  color: #fff;
}
p, a, label, button[type=submit] {
  font-family: gilroy, sans-serif;
  text-transform: uppercase;
  line-height: 121%;
  color: #fff;
}
a {
  font-size: 12px;
  color: #fff;
}
hr {
  border: none;
  height: 1px;
}
.o-desktop {
  display: none !important;
}
.o-mobile {
  display: inherit !important;
}
.o-section {
  margin: 0 7vw;
}
.o-accent {
  font-family: mazius, sans-serif;
}
h1 .o-accent, h2 .o-accent, h3 .o-accent {
  color: #14CF93;
}
@keyframes colorSpin {
  0% {
    filter: hue-rotate(0deg);
  }
  25% {
    background-position: 0 100%;
  }
  50% {
    background-position: 60% 80%;
    filter: hue-rotate(140deg);
  }
  75% {
    background-position: 60% 100%;
  }
  100% {
    filter: hue-rotate(0deg);
  }
}
.o-title {
  font-size: 80px;
  letter-spacing: -2px;
}
.o-title > span {
  overflow: hidden;
}
.o-title-small {
  display: block;
  font-size: 12px;
  color: #A2A2A2;
  font-family: gilroy, sans-serif;
  text-transform: uppercase;
  margin-bottom: 25px;
}
.line-parent {
  overflow: hidden;
  display: block;
}
.line-child {
  display: block;
  transform-origin: center bottom;
  transform-style: preserve-3d;
}
.o-ui-arrow {
  stroke: #fff;
}
.c-cursor {
  display: none;
}
nav {
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: 5vh;
  padding: 0 7vw;
  z-index: 10;
}
nav a {
  font-size: 12px;
  text-transform: uppercase;
  font-family: gilroy, sans-serif;
  color: #fff;
}
nav.--white {
  color: #fff;
}
.c-title {
  padding: 16vh 0 22vh 0;
}
.c-title__row h2 {
  letter-spacing: -4px;
  font-size: 78px;
}
@media (min-width: 320px) {
  .c-title__row h2 {
    font-size: calc(11.6071428571vw + 40.8571428571px);
  }
}
@media (min-width: 768px) {
  .c-title__row h2 {
    font-size: 130px;
  }
}
.c-title__row #Visual path, .c-title__row #Visual polyline {
  stroke: #14CF93;
}
._title-fit {
  line-height: 115%;
  margin-top: -2%;
}
.c-t-a-info p {
  text-transform: uppercase;
  font-size: 12px;
}
.c-t-a-info p:nth-child(1) {
  margin-right: 10vw;
}
.c-t-a-info p > span {
  display: block;
}
.c-t-a-info {
  display: flex;
  padding-top: 10vh;
}
.c-t-a-info p:nth-child(1) {
  margin-right: 10vw;
}
.c-t-a-info--desktop {
  display: none;
}
.c-title__svg {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.c-title__svg svg {
  width: 210px;
  margin: 4px 0;
  pointer-events: none;
}
@media (min-width: 320px) {
  .c-title__svg svg {
    width: calc(-17.8571428571vw + 267.1428571429px);
  }
}
@media (min-width: 768px) {
  .c-title__svg svg {
    width: 130px;
  }
}
.c-visual-line {
  width: 15vw;
  height: 6px;
  background-color: #14CF93;
  margin: 2px 3vw 0 0;
  transform-origin: left;
}
.c-about {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 15vh;
}
.c-about > div:first-child {
  margin-top: 20vh;
}
.c-about > div:first-child a {
  display: flex;
  align-items: center;
  margin-left: calc(26vw - 7vw);
  margin-top: 50px;
}
.c-about > div:first-child a img {
  width: 45px;
  margin-right: 14px;
}
.c-about p {
  font-size: 31px;
  text-transform: uppercase;
  width: 100%;
  line-height: 95%;
}
.c-about p .u-non {
  text-transform: capitalize;
  color: #14CF93;
}
.c-about-cards {
  width: 100%;
  flex-basis: 50%;
  position: relative;
  justify-content: center;
  align-items: center;
}
.c-about-cards > div {
  width: 75%;
  overflow: hidden;
  border-collapse: separate;
  border-radius: 30px;
}
.c-about-cards > div img {
  width: 105%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  filter: saturate(0);
  transition: 0.4s;
}
.c-about-cards > div:hover img {
  filter: saturate(1);
}
.c-about-cards > div:nth-child(1) {
  top: -15%;
  left: 0;
  position: absolute;
  transform: rotate(-6deg);
}
.c-about-cards > div:nth-child(2) {
  position: relative;
  z-index: 2;
  transform: translate(16.6%, 0);
}
.c-about-cards > div:nth-child(3) {
  top: 15%;
  right: 0;
  position: absolute;
  transform: rotate(5deg);
  z-index: 3;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.c-works {
  padding: 28vh 7vw 8vh 7vw;
  position: relative;
}
.c-works hr {
  width: 100%;
  background-color: rgba(150, 148, 148, 0.4);
  margin: 12vh 0;
  transform: scaleX(0);
  transition: 1.5s cubic-bezier(0, 0.89, 0.41, 1);
  transition-delay: 0.2s;
  transform-origin: left;
}
.c-works hr.is-inview {
  transform: scaleX(1);
}
.c-works .c-work-bg {
  --clr1: $secondary-bg;
  --clr2: $secondary-bg;
  --clr3: $secondary-bg;
  --clr4: $secondary-bg;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: 0.6s;
}
.c-works .c-work-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #282828;
  z-index: 0;
  opacity: 1;
  transition: 0.6s;
}
.c-works .c-work-bg.--transparent::before {
  opacity: 0;
}
.c-services {
  display: flex;
}
.c-services svg {
  width: 26vw;
  transform: translate(0, -100%);
}
.c-services > div {
  margin: 10px 0 0 0;
}
.c-services p {
  width: 86%;
  font-size: 22px;
}
.c-works_title {
  margin-bottom: 10vh;
}
.c-works_title h2 {
  display: block;
}
.c-works_title h2 > span:last-child {
  text-align: right;
  margin-top: -4%;
}
.c-works_title svg {
  transform: translate(5vw, 2vw) rotate(45deg);
  width: 14vw;
  margin-top: 4vh;
}
.c-project__row {
  display: block;
}
.c-project__row .u-width-1, .c-project__row .u-width-2, .c-project__row .u-width-3, .c-project__row .u-width-4 {
  width: 100%;
}
.c-project {
  position: relative;
  overflow: hidden;
  border-radius: 40px;
  margin: 7vh 0;
}
.c-project a {
  padding-top: 52%;
  display: block;
  text-decoration: none;
}
.c-project a img {
  position: absolute;
  width: 102%;
  transform: scale(1.15);
  left: -2%;
  top: 0;
  will-change: transform;
}
.c-project-info {
  width: auto;
  position: relative;
  z-index: 2;
  text-decoration: none;
  padding: 0 0 20px 20px;
}
.c-project-info p {
  width: max-content;
  border-radius: 200px;
}
.c-project-info > p:first-child {
  transition-delay: 0.1s;
}
.c-project-info > p:nth-child(2) {
  transition-delay: 0.2s;
}
.c-project-info > p {
  display: block;
  padding: 12px 30px;
  font-size: 10px;
  background: #fff;
  color: #282828;
  margin: 10px 0;
  opacity: 0;
  transform: translate(0, -50%);
  transition: 0.4s;
}
.c-project:hover .c-project-info > p {
  opacity: 1;
  transform: translate(0, 0);
}
.c-project:hover .c-project-info > p:nth-child(2) {
  transition-delay: 0.3s !important;
}
.c-project-cta {
  display: flex;
}
.c-project-cta p {
  font-size: 22px;
  text-transform: none;
  background: #fff;
  padding: 14px 38px;
  margin-right: 12px;
  color: #282828;
}
.c-prj-btn {
  background-color: #fff;
  border-radius: 100px;
  padding: 18px;
  display: flex;
  height: max-content;
}
.c-prj-btn svg {
  width: 18px;
}
.c-archive {
  margin-left: 26vw;
  margin-top: 15vh;
}
.c-archive li {
  margin: 5px 0;
}
.c-archive li::after {
  content: "🡥";
  display: inline-block;
  width: 10px;
  height: 10px;
  padding-left: 5px;
  opacity: 0;
  transition: 0.6s;
  color: #14CF93;
  transition-timing-function: cubic-bezier(0, 0.89, 0.41, 1);
  transform: translateY(-4px);
}
.c-archive li:hover::after {
  padding-left: 10px;
  opacity: 1;
}
.c-archive a {
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  text-decoration: none;
}
.c-archive a::after, .c-archive a::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: #4E4E4E;
  height: 0.03em;
  transition: 0.8s;
  transition-timing-function: cubic-bezier(0, 0.89, 0.41, 1);
  transition-property: transform;
}
.c-archive a::after {
  right: 0;
  transform-origin: right;
}
.c-archive a::before {
  left: 0;
  transform-origin: left;
  transform: scale(0, 1);
  transition-delay: 0.2s;
}
.c-archive a:hover::after {
  transform: scale(0, 1);
  transition: 0;
}
.c-archive a:hover::before {
  transform: scale(1, 1);
  transition-delay: 0;
}
.c-play-btn {
  width: 100%;
  padding: 22px 0 !important;
  text-align: center;
  display: block;
  border: 1px solid rgba(150, 148, 148, 0.4) !important;
  border-radius: 100px;
  margin-top: 15vh !important;
  text-transform: capitalize;
  font-size: 16px;
  text-decoration: none;
  color: #fff;
}
.c-honors {
  padding: 12vh 0 12vh 0;
}
.c-honors__title {
  display: block;
  margin-bottom: 8vh;
}
.c-honors__title h2 {
  transform: translate(-10px, 0);
}
.c-honors__title > span {
  margin-top: 5vh;
  margin-left: 26vw;
}
.c-honors__wrap {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}
.c-honors__wrap > div {
  width: 100%;
}
.c-honors__wrap > div:first-child {
  padding-top: 10vh;
  text-align: right;
}
.c-honors__wrap > div:first-child svg {
  transform: rotate(90deg);
  text-align: right;
  width: 20vw;
}
.c-featured {
  text-align: left;
  padding: 2vh 0 1vh 0;
}
.c-featured a {
  text-decoration: none;
}
.c-featured p {
  text-transform: none;
  font-size: 25px;
  padding-bottom: 20px;
  width: 80%;
  color: #fff;
}
.c-featured p:last-child {
  font-size: 12px;
  padding-bottom: 0;
  padding-top: 20px;
  color: #A2A2A2;
}
.c-featured img {
  width: 100%;
  border-radius: 18px;
}
.c-honors__list {
  padding-bottom: 4vh;
}
.c-honors__list li {
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
  border-bottom: 1px solid rgba(150, 148, 148, 0.4);
}
.c-honors__list li .o-title-small {
  width: 26vw;
  font-size: 10px;
  margin-bottom: 0 !important;
}
.c-honors__list li a {
  width: 48px;
  height: 48px;
  background: #fff;
  border-radius: 40px;
  text-align: center;
}
.c-honors__list li a img {
  width: 20px;
  margin-top: 20px;
  transition: 0.4s cubic-bezier(0, 0.89, 0.41, 1);
}
.c-honors__list li a:hover img {
  transform: rotate(-45deg);
}
.c-honors__list li div {
  display: flex;
  width: 80%;
  align-items: center;
}
.c-honors__list li div p {
  font-size: 24px;
  color: #fff;
  text-transform: capitalize;
  overflow: hidden;
}
.c-honors__list li div p span {
  display: block;
}
footer {
  width: 100%;
  background-color: #2E2E2E;
  padding: 10vh 7vw 0 7vw;
  position: relative;
  overflow: hidden;
}
footer #c-circle {
  position: absolute;
  bottom: -25%;
  width: 200%;
  left: 50%;
  transform: translate(-50%, 0);
}
.c-footer {
  display: block;
  color: #fff;
}
.c-footer > div:first-child {
  position: relative;
}
.c-footer h2 {
  color: #fff;
}
.c-footer__title {
  margin-bottom: 8vh;
  text-transform: capitalize;
  text-align: center;
}
.c-footer__title h2 {
  font-size: 68px;
}
@media (min-width: 320px) {
  .c-footer__title h2 {
    font-size: calc(5.8035714286vw + 49.4285714286px);
  }
}
@media (min-width: 768px) {
  .c-footer__title h2 {
    font-size: 94px;
  }
}
.c-footer__cta {
  width: 100%;
  margin-top: 3vh;
  margin-bottom: 5vh;
}
.c-footer__cta > div {
  width: 100%;
  margin: 18px 0;
}
.c-footer__cta > div a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  text-transform: none;
  padding: 26px 0;
  border: 1px solid #fff;
  border-radius: 100px;
  width: 100%;
  display: block;
  text-align: center;
  font-family: gilroy-semi-bold;
}
.c-footer__socials {
  padding-bottom: 8vh;
  position: relative;
  z-index: 5;
}
.c-footer__socials > div:first-child {
  display: block;
  text-transform: none;
  margin: 18vh 0 18vh 0;
}
.c-footer__socials > div:first-child.is-inview > div {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
}
.c-footer__socials > div:first-child > div {
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transform: translate(0, 60%);
  transition: 0.9s;
  transition-delay: calc(var(--order) * 100ms);
}
.c-footer__socials > div:first-child > div a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  padding: 25px 0;
  border-top: 1px solid #fff;
  transition: 0.6s cubic-bezier(0, 0.89, 0.41, 1);
  position: relative;
  overflow: hidden;
}
.c-footer__socials > div:first-child > div a::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate(0, -101%);
  content: "";
  background-color: #14CF93;
  transition: 0.2s;
  z-index: -1;
}
.c-footer__socials > div:first-child > div a p {
  text-transform: none;
  color: #fff;
}
.c-footer__socials > div:first-child > div a p:first-child {
  font-size: 24px;
  padding-bottom: 4px;
}
.c-footer__socials > div:first-child > div a p:last-child {
  font-size: 12px;
  padding-bottom: 4px;
  opacity: 0.6;
}
.c-footer__socials > div:first-child > div a:hover {
  padding: 25px 20px;
  border-top: 1px solid transparent;
}
.c-footer__socials > div:first-child > div a:hover::after {
  transform: translate(0, 0);
}
.c-footer__socials a img {
  width: 44px;
}
.c-footer__copy {
  margin-top: 25px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
  text-transform: capitalize;
}
.c-footer__copy p, .c-footer__copy a {
  color: inherit;
  text-transform: inherit;
  font-size: inherit;
}
.c-footer__copy div {
  margin-top: 10px;
}
.c-footer-credit {
  text-decoration: underline;
  cursor: pointer;
}
.c-footer-credit:active .c-credits {
  opacity: 1;
  transform: translate(0, 0);
}
.c-credits {
  position: fixed;
  background-color: #fff;
  width: 100%;
  left: 0;
  bottom: 0;
  opacity: 1;
  transition: 0.4s cubic-bezier(0, 0.89, 0.41, 1);
  font-family: gilroy, sans-serif;
  padding: 0 7vw 5vh 7vw;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
}
.c-credits.--visible {
  opacity: 1;
  visibility: visible;
}
.c-credits.--visible div {
  opacity: 1;
  transition-delay: calc(var(--order) * 100ms);
  transform: translate(0, 0);
}
.c-credits div {
  margin-top: 50px;
  opacity: 0;
  transform: translate(0, 20px);
  transition: 0.4s;
}
.c-credits img {
  background-color: #14CF93;
  padding: 20px;
  border-radius: 5vh;
}
.c-credits p {
  line-height: 18px;
  font-size: 12px;
  color: #2E2E2E;
  padding-bottom: 5px;
}
.c-credits a {
  font-size: 24px;
  text-transform: capitalize;
  margin: 10px 0;
  color: #151515;
  display: block;
}
.c-loader {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  text-align: center;
  pointer-events: none;
  color: #fff;
}
.c-loader .c-loader__bg {
  position: absolute;
  transform-origin: top;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #161616;
  transition: 1.6s;
  transition-timing-function: cubic-bezier(0, 0.89, 0.41, 1);
}
.c-loader .c-loader__bg:nth-child(2) {
  z-index: 3;
  transition-delay: 0.5s;
}
.c-loader .c-loader__bg:nth-child(3) {
  background-color: #14CF93;
  z-index: 1;
  transition-delay: 0.8s;
}
.c-loader p {
  transform: translate(-50%, 0);
  position: absolute;
  left: 50%;
  font-size: 12px;
  z-index: 5;
  color: #fff;
}
.c-loader p:first-child {
  top: 5vh;
}
.c-loader h2 {
  font-size: 35px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  transform: translate(-50%, -50%);
  display: flex;
  line-height: 105%;
  transition: 0.6s;
}
.c-loader h2 > span {
  display: block;
  margin: 4px 0;
  overflow: hidden;
}
.c-loader h2 > span span {
  white-space: pre;
  display: block;
  transform-origin: top left;
}
.c-loader .js-split .line-child {
  transition: 1.5s;
  transition-timing-function: cubic-bezier(0, 0.89, 0.41, 1);
}
.c-loader h2.is-loading {
  transform: translate(-40%, -50%);
}
.c-loader h2.is-loading > span:nth-child(1) {
  transform: translate(0, 0);
}
.c-loader h2.is-loading > span:nth-child(2) {
  transform: translate(150%, 0);
}
.c-loader h2.is-loading > span:nth-child(3) {
  transform: translate(300%, 0);
}
.c-loader h2.is-loading > span:nth-child(2), .c-loader h2.is-loading > span:nth-child(3) {
  visibility: hidden;
  opacity: 0;
}
.c-loader h2.is-loaded {
  transform: translate(-50%, -50%);
}
.c-loader h2.is-loaded > span:nth-child(2), .c-loader h2.is-loaded > span:nth-child(3) {
  transition: 0.8s;
  transition-timing-function: cubic-bezier(0, 0.89, 0.41, 1);
  transition-delay: calc(var(--order) * 50ms);
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}
.c-loader .c-loading__anim {
  display: inline-block;
  width: 30px;
  height: 30px;
  z-index: 10;
  position: absolute;
  transform: translate(-50%, -10%);
  bottom: 5vh;
}
.c-loader .c-loading__anim:after {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.c-loader.is-loading .c-loader__bg {
  transform: translate(0, 0);
}
.c-loader.is-loaded {
  visibility: hidden;
}
.c-loader.is-loaded h2.is-loaded {
  transform: translate(-50%, -50%);
}
.c-loader.is-loaded h2.is-loaded > span span {
  transform: translate(0, -110%);
  transition: transform 0.7s;
  transition-timing-function: cubic-bezier(0, 0.89, 0.41, 1);
  transition-delay: calc(var(--order) * 40ms);
}
.c-loader.is-loaded .js-split:nth-child(1) .line-child {
  transform: translate(0, -100%);
}
.c-loader.is-loaded .c-loader__bg {
  transform: translate(0, -100%);
}
.c-play__bg, .c-home__bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #282828;
  transform: translate(0, 100%);
  z-index: 8;
}
.c-home__bg {
  background-color: #151515;
  z-index: 9;
}
.c-play__bg.--play {
  transform: translate(0, 0);
  z-index: 0;
}
.c-play-title {
  padding: 25vh 0 0 0;
  justify-content: space-between;
  align-items: flex-end;
  overflow-x: hidden;
}
.c-play-title a {
  display: flex;
  align-items: center;
  transform: translate(0, -83%);
}
.c-play-title a img {
  width: 50px;
  margin-right: 20px;
  transform: rotate(90deg);
  filter: invert(1);
}
.c-play-title h2 {
  line-height: 112%;
  margin-right: 10%;
  font-size: 70px;
}
.o-play-row {
  display: block;
}
.o-play-row img {
  width: 100%;
  margin: 2vh 0;
}
.o-play-row.u-first-row {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 10vh;
}
.button {
  pointer-events: auto;
  cursor: pointer;
  border: none;
  padding: 1.5rem 3rem;
  margin: 0;
  position: relative;
  display: inline-block;
}
.button::before,
.button::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.button--bestia {
  font-size: 1.15rem;
  background: none;
  padding: 0;
}
.button--bestia .button__bg {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 200px;
  overflow: hidden;
  transition: transform 0.9s cubic-bezier(0, 0.89, 0.41, 1);
}
.button--bestia .button__bg::before,
.button--bestia .button__bg::after {
  content: "";
  position: absolute;
  background: #fff;
}
.button--bestia .button__bg::before {
  width: 110%;
  height: 0;
  padding-bottom: 95%;
  top: 50%;
  left: 50%;
  border-radius: 200px;
  transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 1);
  opacity: 1;
}
.button--bestia:hover .button__bg::before {
  transition: all 0.6s cubic-bezier(0, 0.89, 0.41, 1);
  transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
}
.button--bestia .button__bg::after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.6s;
}
.button--bestia:hover .button__bg::after {
  opacity: 1;
  transition-duration: 0.6s;
  transition-delay: 0.15s;
}
.button--bestia > span {
  display: block;
}
.button--bestia:hover > span {
  mix-blend-mode: difference;
}
#awwwards {
  display: none;
}
/*# sourceMappingURL=index.24a51c92.css.map */
